// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}




































































































































































































































.filter {
  position: relative;
  z-index: 1;
  & + a,
  & + div,
  & + button {
    margin-left: 15px;
  }
  .button-main {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
    }
  }

  .vdp-datepicker__calendar {
    left: 0;
    bottom: 0;
    transform: translateY(100%);
  }

  .form-item-date {
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -6px;
      width: 12px;
      height: 13.33px;
      opacity: 0.8;
      background: url("/img/icon/calendar.svg") no-repeat center;
    }

    input {
      padding-right: 30px;
    }
  }

  .form-item.search {
    .input-wrap {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -6px;
        opacity: 1;
        width: 12px;
        height: 12px;
        background: url("/img/icon/search.svg") no-repeat center;
      }
    }

    input {
      padding-right: 35px;
    }
  }

  &-item {
    &__title {
      color: #000;
      margin-bottom: 10px;
    }

    & + .filter-item {
      margin-top: 20px;
    }
  }
  .filter-item-date {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        width: 47%;
      }
    }
  }
  .button-group {
    button {
      width: 50%;
      background: transparent;
      height: 60px;
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      border: 1px solid #f6f6f8;
      border-bottom: none;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &.apply {
        color: #6f61e9;
      }

      &.clear {
        color: #f5383b;
      }
    }
  }

  &-views-wrap {
    position: absolute;
    transform: translateY(100%);
    width: 355px;
    bottom: -8px;
    right: 0px;
    background: linear-gradient(0deg, #fff, #fff);
    box-shadow: 0px 8px 24px #c5c2d1;
    border-radius: 12px;

    &.fade-enter-active,
    &.fade-leave-active {
      transition: all 0.5s;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
      transform: translateY(115%);
    }
  }
  &-views {
    padding: 25px;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        .form-item-checkbox {
          display: block;
          label {
            width: 100%;
          }
        }
      }
    }
  }
}
