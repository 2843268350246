// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}































































































































































































.form-item {
  .label {
    font-size: 14px;
    color: #808080;
    width: calc(50% - 5px);

    & + .form-item-select {
      width: calc(50% - 5px);
      margin-left: 10px;
    }
  }

  &-min {
    .label {
      width: calc(50% - 5px);

      & + .form-item-select {
        width: 25%;
        margin-left: 10px;
      }
    }
  }

  &.invalid {
    .form-item-select__control {
      border: 1px solid #ff7b7b;
      background: #fff2f2;

      &:focus,
      &:hover {
        border: 1px solid #ff7b7b;
      }
    }
  }
}

.form-item-select {
  width: 100%;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  &.select-is-focus {
    .form-item-select__control {
      outline: 0;
      outline-offset: 0;
      border: 1px solid #6f61e9;
    }
  }

  &.select-is-open {
    z-index: 5;

    .form-item-select__control {
      outline: 0;
      outline-offset: 0;
      border: 1px solid #6f61e9;
    }
  }
  &.select-is-disabled {
    .form-item-select__control {
      background: #f6f6f8;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid transparent;
      }
    }
  }

  label {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    margin-bottom: 5px;
  }

  &__menu {
    position: absolute;
    width: 100%;
    max-height: 250px;
    box-shadow: 0px 16px 24px rgba(221, 218, 235, 0.36);
    border-radius: 4px;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: -1;

    &_bottom {
      bottom: -10px;
      transform: translateY(100%);

      &-enter-active,
      &-leave-active {
        transform: translateY(100%);
        transition: 0.4s cubic-bezier(0.19, 0.36, 0.49, 1.1);
        transition-property: opacity, transform;
      }

      &-enter,
      &-leave-to {
        transform: translateY(calc(100% + 13px));
        opacity: 0;
      }
    }

    &_top {
      top: -10px;
      transform: translateY(-100%);

      &-enter-active,
      &-leave-active {
        transform: translateY(-100%);
        transition: 0.4s cubic-bezier(0.19, 0.36, 0.49, 1.1);
        transition-property: opacity, transform;
      }

      &-enter,
      &-leave-to {
        transform: translateY(calc(-100% - 13px));
        opacity: 0;
      }
    }

    &-option {
      padding: 8px 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: normal;
      color: #000;
      background: rgba(255, 255, 255, 0);
      transition: 0.3s ease;
      transition-property: color, background;
      cursor: pointer;

      &:hover {
        background: rgba(111, 97, 233, 0.1);
        color: #6f61e9;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 5px;
    }
  }

  &__control {
    display: flex;
    width: 100%;
    background: #fff;
    border: 1px solid #e0dfee;
    height: 50px;
    border-radius: 4px;
    transition: 0.25s ease;
    transition-property: border;

    &:hover {
      border: 1px solid #6f61e9;
    }
  }

  &__indicators {
    display: flex;
    width: 25px;
    flex: 0 0 25px;
    padding: 0 5px;

    span {
      position: relative;
      transform: translateY(-3px);
      margin: auto;

      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border: solid #cccbd9;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  &__container {
    width: 100%;
    max-width: calc(100% - 25px);
    height: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  &__value {
    font-size: 14px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__single-value,
  &__placeholder {
    color: #333;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__input {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    left: 0;
    position: absolute;
    display: none;

    input {
      box-sizing: content-box;
      width: 2px;
      background: 0px center;
      border: 0px;
      font-size: inherit;
      opacity: 0;
      outline: 0px;
      padding: 0px;
      visibility: hidden;
      color: inherit;
    }
  }
}

.ie {
  .form-item-select__indicators {
    width: 15px;
    flex: 0 0 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin: 0;
    }
  }
}
